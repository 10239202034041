/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { getTranslate } from 'react-localize-redux';
import { withFormik, Form } from 'formik';
import * as yup from 'yup';
import Button from '../components/Button';
import FormField from '../components/FormField';
import { Select } from '../components/Select';
import localizationFi from '../localization/fi.json';
import localizationSv from '../localization/sv.json';
import localizationEn from '../localization/en.json';
import getLanguage from '../utils/getLanguage';

const getCountries = language => {
  switch (language) {
    case 'sv':
      return localizationSv.countries;
    case 'en':
      return localizationEn.countries;
    default:
      return localizationFi.countries;
  }
};

const ItemForm = ({ currency, translate, handleSubmit, isSubmitting, setFieldValue, close }) => {
  const language = useSelector(state => getLanguage(state));
  const countries = getCountries(language);

  return (
    <Form sx={{ p: [3, 3, 4], maxHeight: '100vh', overflowY: 'scroll' }}>
      <Box sx={{ maxWidth: 640 }}>
        <FormField name="description" label={translate('buyParcel.customs.item.description') + ' *'} />
        <Box sx={{ mb: 3 }}>{translate('buyParcel.customs.item.description2')}</Box>
        <Flex sx={{ alignItems: 'flex-end' }}>
          <Box sx={{ width: '40%' }}>
            <FormField
              name="count"
              label={translate('buyParcel.customs.item.count') + ' *'}
              type="number"
              min={0}
              step={1}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ width: '50%' }}>
            <FormField
              name="weight"
              label={translate('buyParcel.customs.item.weight') + ' *'}
              type="number"
              min={0}
              step={1}
            />
          </Box>
        </Flex>
        <Flex sx={{ alignItems: 'center' }}>
          <Box sx={{ width: '40%', minWidth: '120px' }}>
            <FormField
              name="value"
              label={translate('buyParcel.customs.item.value') + ' *'}
              type="number"
              min={0}
              step={1}
            />
          </Box>
          <Box sx={{ fontWeight: 'medium', ml: 2, mb: '-12px' }}>{currency}</Box>
        </Flex>
        <Flex>
          <Box sx={{ width: '58%' }}>
            <FormField as={Select} name="country" label={translate('buyParcel.customs.item.country')}>
              <option value={''}>{translate('buyParcel.customs.item.noCountry')}</option>
              {Object.entries(countries).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </FormField>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ width: '35%' }}>
            <FormField
              name="tariffNumber"
              label={translate('buyParcel.customs.item.tariff')}
              placeholder={translate('buyParcel.customs.item.tariffPlaceholder')}
            />
          </Box>
        </Flex>
        <Flex sx={{ mt: 4 }}>
          <Button sx={{ minWidth: '26%' }} type="submit" onClick={handleSubmit} disabled={isSubmitting}>
            {translate('buyParcel.customs.item.save')}
          </Button>
          <Button sx={{ ml: 4, minWidth: '26%' }} type="button" variant="secondary" onClick={close}>
            {translate('buyParcel.customs.item.cancel')}
          </Button>
        </Flex>
      </Box>
    </Form>
  );
};

const itemSchema = translate =>
  yup.object().shape({
    description: yup.string().required(translate('form.requiredField')),
    count: yup
      .number()
      .required(translate('form.requiredField'))
      .positive(translate('form.invalidInteger'))
      .integer(translate('form.invalidInteger')),
    weight: yup
      .number()
      .required(translate('form.requiredField'))
      .positive(translate('form.invalidInteger'))
      .integer(translate('form.invalidInteger')),
    value: yup
      .number()
      .min(0, translate('form.invalidInteger'))
      .required(translate('form.requiredField')),
    country: yup.string(),
    tariffNumber: yup.string().matches(/(^[0-9]{8})$|(^[0-9]{10})$/, translate('form.invalidTariffCode')),
  });

const ItemFormik = withFormik({
  mapPropsToValues: ({ item: { description, count, weight, value, country, tariffNumber } }) => ({
    description,
    count,
    weight,
    value,
    country,
    tariffNumber,
  }),
  validationSchema: ({ translate }) => itemSchema(translate),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },

  displayName: 'ItemForm',
})(ItemForm);

const modalStyles = {
  overlay: { zIndex: 1000 },
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default ({ item, close }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  Modal.setAppElement('#___gatsby');

  const onSubmit = useCallback(
    async values => {
      if (typeof item.save !== 'function') {
        console.warn('item save is not a function');
        return;
      }
      item.save(values);
      close();
    },
    [item, close]
  );
  const formProps = {
    item,
    currency: (item || {}).currency || 'EUR',
    onSubmit,
    close,
    translate,
  };

  if (!item) {
    return null;
  }

  return (
    <Modal isOpen={!!item} style={modalStyles} contentLabel="Example Modal">
      <ItemFormik {...formProps} />
    </Modal>
  );
};
