/** @jsx jsx */
import { jsx, Flex, Box, Styled, Label } from 'theme-ui';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import { goBack, goNext } from '../state/parcelFlow';
import CountrySelector from './CountrySelector';
import { locNavigate } from '../state/session';
import { loadProductsForCountry } from '../state/catalog';
import getLanguage from '../utils/getLanguage';
import { CountryCode, localizedCountryNames } from '../utils/countryCode';

const mostPopularCountries = [
  process.env.NODE_ENV === 'development' ? CountryCode.Russia : null,
  // CountryCode.Finland,
  CountryCode.Aland,
  CountryCode.Sweden,
  CountryCode.Estonia,
  // CountryCode.Germany,
  CountryCode.Denmark,
  CountryCode.France,
  // CountryCode.Latvia,
  // CountryCode.Lithuania,
].filter(cc => !!cc);

const CountryCard = ({ country: { code, name }, isSelected }) => {
  const language = useSelector(state => getLanguage(state));
  const localizedName = name[language] || name.fi;
  if (code === 'uk') {
    code = 'gb';
  }

  // flag images: npm i svg-country-flags
  return (
    <Flex
      sx={{
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 120,
        height: '100%',
        minHeight: 160,
        maxHeight: 200,
        py: 3,
        px: 2,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: isSelected ? 'primary' : 'border',
        borderRadius: 1,
        color: isSelected ? 'white' : 'inherit',
        backgroundColor: isSelected ? 'primary' : 'white',
        ':hover': {
          borderColor: isSelected ? 'primary' : 'grayLight',
          cursor: 'pointer',
        },
      }}
    >
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <img
          src={'/flags/' + code.toLowerCase() + '.svg'}
          alt={'lippu ' + localizedName}
          sx={{ flexGrow: 1, height: 64, width: 64, my: 3 }}
          onError={e => (e.target.style = 'visibility: hidden')}
        />
        <span id={`label-${code}`} sx={{ fontWeight: 'medium', textAlign: 'center' }}>
          {localizedName}
        </span>
      </Flex>
    </Flex>
  );
};

const MostPopularList = () => {
  const dispatch = useDispatch();
  const container = useRef();
  const selectedCountry = useSelector(state => state.parcelFlow.country || 'FI');
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = useCallback(
    e => {
      let countryElements = [];
      if (container.current) {
        countryElements = Array.from(container.current.querySelectorAll('.country'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : mostPopularCountries.length - 1;
            const elem = countryElements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < mostPopularCountries.length - 1 ? i + 1 : 0;
            const elem = countryElements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            const countryCode = mostPopularCountries[i];
            if (countryCode) {
              setImmediate(() => {
                dispatch(loadProductsForCountry(countryCode, true));
              });
            }
            return i;
          });
          break;
      }
    },
    [setFocusIndex, dispatch]
  );

  return (
    <Flex
      ref={container}
      sx={{
        flexWrap: ['nowrap', null, 'wrap'],
        overflowX: ['auto', null, 'visible'],
        mb: 3,
        mx: [-3, null, 0],
      }}
      role="radiogroup"
      aria-labelledby="most-common-label"
      onKeyDown={onKeyDown}
    >
      {mostPopularCountries.map((countryCode, index) => {
        const country = localizedCountryNames.find(c => c.code === countryCode);
        return (
          <Box
            key={country.code}
            className="country"
            onClick={() => dispatch(loadProductsForCountry(country.code, true))}
            sx={{
              flex: 'none',
              mr: 2,
              mb: 2,
              ':first-of-type': {
                pl: [3, null, 0],
              },
              ':last-of-type': {
                pr: 3,
              },
            }}
            role="radio"
            aria-labelledby={`label-${country.code}`}
            tabIndex={focusIndex === index ? 0 : -1}
          >
            <CountryCard country={country} isSelected={country.code === selectedCountry} />
          </Box>
        );
      })}
    </Flex>
  );
};

export default () => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const alreadyInBasket = useSelector(state => !!state.parcelFlow.rowId);
  const backToShoppingBasket = useCallback(() => {
    dispatch(locNavigate('/ostoskori'));
  }, [dispatch]);
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const onNextClick = useCallback(() => dispatch(goNext()), [dispatch]);

  return (
    <FullHeightColumn>
      <Box>
        {alreadyInBasket ? (
          <Button onClick={backToShoppingBasket} variant="plain" sx={{ color: 'primary' }}>
            {translate('buyParcel.backToBasket')}
          </Button>
        ) : (
          <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
            {translate('buyParcel.backButton')}
          </Button>
        )}
      </Box>
      <Styled.h1 sx={{ color: 'secondary' }}>{translate('buyParcel.country.title')}</Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Label sx={{ color: 'secondary', mb: 2 }} id="most-common-label">
          {translate('buyParcel.country.mostCommon')}
        </Label>
        <MostPopularList />
        <Label sx={{ color: 'secondary', mb: 2 }}>{translate('buyParcel.country.select')}</Label>
        <CountrySelector ignoreCountries={mostPopularCountries} />
      </Flex>
      <Flex
        sx={{
          mt: 3,
          flexDirection: ['row-reverse', null, 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Button sx={{ alignSelf: ['flex-end', null, 'flex-start'], flex: 'none' }} onClick={onNextClick}>
          {translate('buyParcel.continue')}
        </Button>
      </Flex>
    </FullHeightColumn>
  );
};
