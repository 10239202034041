/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Button from '../components/Button';
import { SearchIcon } from '../components/Icon';
import Spinner from '../components/Spinner';
import FullHeightColumn from '../components/FullHeightColumn';
import { goBack, goNext, selectRePackPoint } from '../state/parcelFlow';
import AsyncSelect from 'react-select/async';
import ServicePointButton from './ServicePointButton';
import { prefetchPage } from '../state/session';
import { autocompleteAddress, getRepackPoints } from '../utils/api';
import getLanguage from '../utils/getLanguage';
import Alert from '../components/Alert';

export default () => {
  const language = useSelector(state => getLanguage(state));
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [servicePoints, setServicePoints] = useState([]);
  const repackPoint = useSelector(state => state.parcelFlow.repackPoint) || {};
  const product = useSelector(state => state.parcelFlow.product);
  const [originalRepackPoint] = useState(repackPoint);
  const originalRepackPointIncluded = !!servicePoints.find(
    s => s.officeCode === originalRepackPoint.officeCode
  );
  const servicePoints2 =
    originalRepackPoint.officeCode && !originalRepackPointIncluded
      ? [originalRepackPoint, ...servicePoints]
      : servicePoints;
  const currentSelectionInList = !!servicePoints2.find(
    s => s.officeCode === repackPoint.officeCode
  );
  const [isSelectMsgVisible, setSelectMsgVisible] = useState(false);
  const preventDoubleClick = useRef(false);
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const onNextClick = useCallback(() => {
    if (!currentSelectionInList) {
      setSelectMsgVisible(true);
      return;
    }
    if (preventDoubleClick.current) {
      return;
    }
    preventDoubleClick.current = true;
    try {
      dispatch(goNext());
    } finally {
      preventDoubleClick.current = false;
    }
  }, [dispatch, currentSelectionInList]);
  dispatch(prefetchPage('/ostoskori'));

  const selectSuggestion = useCallback(
    async opt => {
      const postcode = opt.postalCode;
      const street = opt.streetAddress;
      setLoading(true);
      let servicePoints = [];
      servicePoints = await getRepackPoints({
        postcode,
        street,
      });
      setLoading(false);
      setServicePoints(servicePoints);
    },
    [setServicePoints, setLoading]
  );

  const selectStyles = {
    container: base => ({
      ...base,
      display: 'inline-block',
      width: 'calc(100% - 40px)',
      verticalAlign: 'bottom',
    }),
    control: base => ({ ...base, border: 'none', boxShadow: 'none' }),
    indicatorsContainer: base => ({ display: 'none' }),
  };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>
        {translate('buyParcel.repack.officeTitle')}
      </Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxWidth: 640,
        }}
      >
        <Alert>{translate('buyParcel.repack.officeDescription')}</Alert>
        <Box sx={{ flex: 'auto', my: 3 }}>
          <div
            sx={{
              border: 0,
              borderBottomColor: 'secondary',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              borderRadius: 0,
            }}
          >
            <SearchIcon sx={{ display: 'inline-block' }} />
            <AsyncSelect
              placeholder={translate('buyParcel.pickup.search')}
              styles={selectStyles}
              loadOptions={term => autocompleteAddress(term, language)}
              getOptionLabel={opt => opt.name}
              isOptionSelected={() => false}
              onChange={selectSuggestion}
              noOptionsMessage={() => null}
              loadingMessage={() => (
                <Box sx={{ py: 2 }}>
                  <Spinner />
                </Box>
              )}
            />
          </div>
          {isSelectMsgVisible ? (
            <div sx={{ color: 'danger', mt: 2 }}>
              {translate('buyParcel.repack.officeSelect')}
            </div>
          ) : null}
          <Box sx={{ mt: 2 }}>
            {isLoading ? (
              <Spinner />
            ) : servicePoints2.length === 0 ? (
              <CenteredHelpText>
                {translate('buyParcel.repack.officeSearchNote')}
              </CenteredHelpText>
            ) : (
              servicePoints2.map(s => (
                <ServicePointButton
                  key={s.officeCode}
                  servicePoint={s}
                  isSelected={s.officeCode === repackPoint.officeCode}
                  onSelect={() => {
                    setSelectMsgVisible(false);
                    dispatch(selectRePackPoint(s));
                  }}
                />
              ))
            )}
          </Box>
        </Box>
        <Flex
          sx={{
            justifyContent: ['flex-end', null, 'flex-start'],
            mt: 3,
          }}
        >
          <Button disabled={servicePoints2.length === 0} onClick={onNextClick}>
            {translate('buyParcel.continue')}
          </Button>
        </Flex>
      </Flex>
    </FullHeightColumn>
  );
};

const CenteredHelpText = ({ children }) => (
  <Styled.p
    sx={{
      p: [4],
      color: 'gray',
      textAlign: 'center',
    }}
  >
    {children}
  </Styled.p>
);
