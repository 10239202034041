/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import { goBack, goNext, selectDeliveryMethod } from '../state/parcelFlow';
import ServiceCard from './ServiceCard';

export const ariaLabelForService = (service, translate) => {
  const translate2 = (id, fallback, params) => translate(id, params || {}, { onMissingTranslation: () => fallback });
  const title = translate2(`buyParcel.services.${service.serviceCode}.title`, service.displayName);
  const description = translate2(`buyParcel.services.${service.serviceCode}.description`, service.description, service);
  return `${title}, ${service.price} €, ${description}`;
};

const ServiceList = ({ services, selectedOption }) => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const container = useRef();
  const [focusIndex, setFocusIndex] = useState(0);
  const onSelect = useCallback(
    serviceCode => {
      dispatch(selectDeliveryMethod(serviceCode));
    },
    [dispatch]
  );

  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.service'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : services.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < services.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            const service = services[i];
            if (service) {
              setImmediate(() => {
                onSelect(service.serviceCode);
              });
            }
            return i;
          });
          e.preventDefault();
          break;
      }
    },
    [services, setFocusIndex, onSelect]
  );

  return (
    <Box
      ref={container}
      sx={{ maxWidth: 640 }}
      role="group"
      aria-label={translate('buyParcel.services.title')}
      onKeyDown={onKeyDown}
    >
      {services.map((s, index) => {
        const isSelected = s.serviceCode === selectedOption;
        return (
          <Box
            sx={{ display: 'relative', width: '100%', my: 2 }}
            key={s.serviceCode}
            onClick={() => onSelect(s.serviceCode)}
            className="service"
            role="checkbox"
            aria-label={ariaLabelForService(s, translate)}
            aria-checked={isSelected}
            tabIndex={focusIndex === index ? 0 : -1}
          >
            <ServiceCard service={s} isSelected={isSelected} onClick={() => onSelect(s.serviceCode)} />
          </Box>
        );
      })}
    </Box>
  );
};

const deliveryOptions = [
  {
    serviceCode: 'JA',
    displayName: 'Jakelu vastaanottajalle',
    price: 0,
    priceVat0: 0,
  },
  {
    serviceCode: 'KJ',
    displayName: 'Kotijakelu',
    price: 0,
    priceVat0: 0,
  },
  {
    serviceCode: 'NMH',
    displayName: 'Nouto Matkahuollosta',
    price: 0,
    priceVat0: 0,
  },
];

const enabledOptions = ['JA', 'KJ'];

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const onNextClick = useCallback(() => dispatch(goNext()), [dispatch]);
  const selectedOption = useSelector(state => state.parcelFlow.delivery);
  const options = useMemo(() => deliveryOptions.filter(o => enabledOptions.includes(o.serviceCode)), []);

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('buyParcel.delivery.title')}</Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: ['space-between'],
        }}
      >
        <ServiceList services={options} selectedOption={selectedOption} />
        <Button
          sx={{
            alignSelf: ['flex-end', null, 'flex-start'],
            mt: 3,
          }}
          onClick={onNextClick}
          disabled={!selectedOption}
        >
          {translate('buyParcel.continue')}
        </Button>
      </Flex>
    </FullHeightColumn>
  );
};
