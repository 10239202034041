/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ServicePointInfo from './ServicePointInfo';
import Button from '../components/Button';
import { SearchIcon } from '../components/Icon';
import Spinner from '../components/Spinner';
import FullHeightColumn from '../components/FullHeightColumn';
import { goBack, goNext, selectConnection } from '../state/parcelFlow';
import AsyncSelect from 'react-select/async';
import { prefetchPage } from '../state/session';
import { getExpressParcelConnections, getExpressParcelStopSuggestion } from '../utils/api';
import { useLanguage, useTranslate } from '../utils/getLanguage';
import moment from 'moment-timezone';
import ConnectionPointButton from './ConnectionPointButton';

function debounce(fn, delay = 250) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

const StopSearch = ({ label, placeholder, onSelect }) => {
  const language = useLanguage();
  const selectStyles = {
    container: base => ({
      ...base,
      display: 'inline-block',
      width: 'calc(100% - 40px)',
      verticalAlign: 'bottom',
    }),
    control: base => ({ ...base, border: 'none', boxShadow: 'none' }),
    indicatorsContainer: _ => ({ display: 'none' }),
  };
  const handleLoadOptions = useCallback(
    debounce(async (term, callback) => {
      if (term.length < 3) {
        callback([]);
        return;
      }
      callback(await getExpressParcelStopSuggestion(term, language));
    }, 500),
    [language]
  );
  return (
    <>
      <Styled.h2 sx={{ color: 'secondary', mt: 0 }}>{label}</Styled.h2>
      <div
        sx={{
          borderWidth: 1,
          borderColor: 'border',
          borderStyle: 'solid',
          borderRadius: 1,
          px: 2,
        }}
      >
        <SearchIcon sx={{ display: 'inline-block' }} />
        <AsyncSelect
          placeholder={placeholder}
          styles={selectStyles}
          loadOptions={handleLoadOptions}
          getOptionLabel={opt => [opt.name, opt.region].filter(it => it).join(', ')}
          isOptionSelected={() => false}
          onChange={onSelect}
          noOptionsMessage={() => null}
          loadingMessage={() => (
            <Box sx={{ py: 2 }}>
              <Spinner />
            </Box>
          )}
        />
      </div>
    </>
  );
};

export default () => {
  const language = useLanguage();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [connections, setConnections] = useState([]);
  const connection = useSelector(state => state.parcelFlow.connection) || {};
  const [originalConnection] = useState(connection);
  const originalPointIncluded = !!connections.find(s => s.id === originalConnection.id);
  const connections2 =
    originalConnection.id && !originalPointIncluded ? [originalConnection, ...connections] : connections;
  const filteredConnections = useMemo(() => {
    if (!connections2) return [];
    const now = moment();
    const filtered = connections2.filter(it =>
      moment(it.fromPlace.dateTime)
        .tz('Europe/Helsinki')
        .isAfter(now)
    );
    return filtered;
  }, [connections2]);
  const currentSelectionInList = !!connections2.find(s => s.id === connection.id);
  const [isSelectMsgVisible, setSelectMsgVisible] = useState(false);
  const preventDoubleClick = useRef(false);
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const onNextClick = useCallback(() => {
    if (!currentSelectionInList) {
      setSelectMsgVisible(true);
      return;
    }
    if (preventDoubleClick.current) {
      return;
    }
    preventDoubleClick.current = true;
    try {
      dispatch(goNext());
    } finally {
      preventDoubleClick.current = false;
    }
  }, [dispatch, currentSelectionInList]);
  dispatch(prefetchPage('/ostoskori'));

  const onSelect = useCallback(
    sp => {
      setSelectMsgVisible(false);
      dispatch(selectConnection(sp));
    },
    [dispatch]
  );

  const [departure, setDeparture] = useState();
  const [arrival, setArrival] = useState();

  const handleSelectDeparture = useCallback(
    opt => {
      setDeparture(opt);
    },
    [setDeparture]
  );

  const handleSelectArrival = useCallback(
    opt => {
      setArrival(opt);
    },
    [setArrival]
  );

  const handleLoadConnections = useCallback(
    async (departure, arrival, dayOffset) => {
      setLoading(true);
      setHasLoaded(false);
      try {
        const { connections } = await getExpressParcelConnections({
          departureStopAreaId: departure.id,
          arrivalStopAreaId: arrival.id,
          allSchedules: 0,
          departureDate: moment()
            .add(dayOffset, 'days')
            .format('YYYY-MM-DD'),
          ticketTravelType: 0,
          filters: 'noChanges',
          services: 'p1',
          language,
        });
        setConnections(val => (dayOffset ? [...(val || []), ...(connections || [])] : connections));
        setHasLoaded(true);
      } catch (e) {
        setLoading(false);
        if (e.message && e.message.includes('status code 502')) {
          if (dayOffset === 0) setConnections([]);
          setHasLoaded(true);
          return;
        }
        console.error(e.message);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [setConnections, language, setLoading, setHasLoaded]
  );

  const [dayOffset, setDayOffset] = useState(0);

  useEffect(() => {
    setDayOffset(0);
    if (!departure || !arrival) {
      return;
    }
    setConnections([]);
    handleLoadConnections(departure, arrival, 0);
  }, [departure, arrival, handleLoadConnections, setDayOffset]);

  const handleShowMore = useCallback(() => {
    handleLoadConnections(departure, arrival, dayOffset + 1);
    setDayOffset(val => val + 1);
  }, [departure, arrival, handleLoadConnections, setDayOffset, dayOffset]);

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('buyParcel.express.shiftTitle')}</Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxWidth: 640,
        }}
      >
        <Box sx={{ flex: 'auto', my: 3 }}>
          <StopSearch
            onSelect={handleSelectDeparture}
            label={translate('buyParcel.express.from')}
            placeholder={translate('buyParcel.express.departureStopHint')}
          />
          <Box sx={{ mt: 4 }} />
          <StopSearch
            onSelect={handleSelectArrival}
            label={translate('buyParcel.express.to')}
            placeholder={translate('buyParcel.express.arrivalStopHint')}
          />
          {isSelectMsgVisible ? (
            <div sx={{ color: 'danger', mt: 2 }}>{translate('buyParcel.express.shiftSelect')}</div>
          ) : null}
          <Box sx={{ mt: 4 }}>
            {filteredConnections.length ? (
              <Box sx={{ pt: 2 }}>
                {filteredConnections.map(c => (
                  <ConnectionPointButton
                    key={c.id}
                    connection={c}
                    isSelected={c.id === connection.id}
                    onSelect={() => onSelect(c)}
                  />
                ))}
              </Box>
            ) : (
              hasLoaded && <Box>{translate('buyParcel.express.noConnections')}</Box>
            )}
            {hasLoaded && (
              <Box sx={{ mt: 2, cursor: 'pointer', fontWeight: 'medium', color: 'primary' }} onClick={handleShowMore}>
                {translate('buyParcel.express.showMore')}
              </Box>
            )}
            {isLoading && (
              <Box sx={{ position: 'relative' }}>
                <Spinner />
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
      <ServicePointInfo sp={connection} connection />
      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 3,
        }}
      >
        <Button disabled={filteredConnections.length === 0} onClick={onNextClick}>
          {translate('buyParcel.continue')}
        </Button>
      </Flex>
    </FullHeightColumn>
  );
};

const CenteredHelpText = ({ children }) => (
  <Styled.p
    sx={{
      p: [4],
      color: 'gray',
      textAlign: 'center',
    }}
  >
    {children}
  </Styled.p>
);
