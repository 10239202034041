/** @jsx jsx */
import { jsx, Flex, Box, useThemeUI } from 'theme-ui';
import { dateToTimezoneDate, dateToTimezoneHour } from '../utils/date';
import { ArrowIcon, BusIcon, LocationIcon } from '../components/Icon';
import { usePlace } from '../components/express/ConnectionInfo';
import { useCallback, useRef, useState } from 'react';
import StopMap from '../components/express/StopMap';

const ARROW_SIZE = 8;
const LOCATION_SIZE = 16;

const PlaceInfo = ({ place, isSelected, onClickPlace }) => {
  const { theme } = useThemeUI();
  const handleClickPlace = useCallback(() => {
    onClickPlace(place);
  }, [place, onClickPlace]);
  return (
    <Flex sx={{ flex: 1, flexDirection: 'column' }}>
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: 1,
            color: isSelected ? 'white' : 'text',
          }}
        >
          {place.placeName}
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={handleClickPlace}>
          <LocationIcon
            sx={{ ml: 2 }}
            width={LOCATION_SIZE}
            height={LOCATION_SIZE}
            bg={isSelected ? theme.colors.primary : theme.colors.bakcground}
          />
        </Box>
      </Flex>
      <Box sx={{ mt: 2, color: isSelected ? 'white' : 'gray', fontSize: 1 }}>{place.stopAreaName}</Box>
      <Box sx={{ flex: 1 }} />
      <Flex
        sx={{
          mt: 2,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <BusIcon
          sx={{
            mr: 2,
            color: isSelected ? 'white' : theme.colors.primary,
          }}
          fill={isSelected ? theme.colors.primary : theme.colors.background}
        />
        <Box
          sx={{
            color: isSelected ? 'white' : 'secondary',
            fontSize: 1,
          }}
        >
          {place.stopName || place.placeName}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ({ connection, onSelect, isSelected, ...props }) => {
  const { theme } = useThemeUI();
  const selectedPlace = useRef(null);
  const { stop, details, callback: handleSelectPlace } = usePlace({ connection });
  const handleClickPlace = useCallback(
    place => {
      const newValue = selectedPlace.current?.digiStopId === place?.digiStopId ? null : place;
      selectedPlace.current = newValue;
      handleSelectPlace(newValue);
    },
    [selectedPlace, handleSelectPlace]
  );
  return (
    <Flex
      {...props}
      sx={{
        py: 12,
        px: 3,
        mt: 2,
        borderRadius: 2,
        borderWidth: '1px',
        borderColor: isSelected ? 'primary' : 'border',
        borderStyle: 'solid',
        bg: isSelected ? 'primary' : 'white',
        color: isSelected ? 'white' : 'text',
        cursor: 'pointer',
        ':hover': {
          bg: isSelected ? 'primary' : 'blueLighter',
        },
        alignItems: 'stretch',
        flexDirection: 'column',
      }}
      onClick={onSelect}
    >
      <Box sx={{ mt: 2, color: isSelected ? 'white' : 'text', fontSize: 1, alignSelf: 'end' }}>
        {dateToTimezoneDate(connection.fromPlace.dateTime)}
      </Box>
      <Flex
        sx={{
          flexDirection: 'row',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              fontSize: 1,
              color: isSelected ? 'white' : 'text',
            }}
          >
            {dateToTimezoneHour(connection.fromPlace.dateTime)} - {dateToTimezoneHour(connection.toPlace.dateTime)}
          </Box>
          <Box sx={{ mt: 2, color: isSelected ? 'white' : 'gray', fontSize: 1 }}>
            {connection.duration.replace(':', 'h ') + 'min'}
            {connection.line.number ? `, ${connection.line.number}` : ''}
          </Box>
          <Box sx={{ mt: 2, color: isSelected ? 'white' : 'gray', fontSize: 1 }}>
            {connection.companies.map(c => c.name).join(', ')}
          </Box>
        </Box>
        <PlaceInfo place={connection.fromPlace} isSelected={isSelected} onClickPlace={handleClickPlace} />
        <ArrowIcon
          fill={isSelected ? 'white' : theme.colors.text}
          width={ARROW_SIZE}
          height={ARROW_SIZE}
          sx={{ mt: 1, mx: 2 }}
        />
        <PlaceInfo place={connection.toPlace} isSelected={isSelected} onClickPlace={handleClickPlace} />
      </Flex>
      <StopMap stop={stop} connection={details.current} />
    </Flex>
  );
};
