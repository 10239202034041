/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { PAGE, getPageOrder, ParcelType } from '../state/parcelFlow';
import { formatPrice } from '../utils/formatters';
import getLanguage from '../utils/getLanguage';
import { getLocalizedCountryName } from '../utils/countryCode';

const pageNames = {
  '1': 'Kohdemaa',
  '2': 'Pakettikoko',
  '3': 'Lisäpalvelut',
  '4': 'Vastaanottaja',
  '5': 'Noutopiste',
  '6': 'Ostoskori',
  '8': 'RePack-nouto',
};
/*
  SELECT_COUNTRY: 1,
  SELECT_PRODUCT: 2,
  SELECT_EXTRAS: 3,
  RECIPIENT: 4,
  PICKUP_POINT: 5,
  ADD_TO_BASKET: 6,
  EMPTY_WAIT_PAGE: 7,
  SELECT_REPACK_OFFICE: 8,
*/

const Step = ({ completed, current, last, children }) => (
  <Box
    sx={{
      position: 'relative',
      color: completed || current ? 'secondary' : 'grayLight',
      pl: [0, null, null, 24],
      pb: [0, null, null, 3],
      width: '100%',
      height: [2, null, null, 'auto'],
      bg: completed || current ? ['secondary', null, null, 'transparent'] : ['border', null, null, 'transparent'],
      '::before': {
        position: 'absolute',
        top: 1,
        left: 0,
        zIndex: 2,
        content: '""',
        display: ['none', null, null, 'block'],
        width: 16,
        height: 16,
        bg: completed ? 'secondary' : 'white',
        borderColor: completed || current ? 'secondary' : 'border',
        borderWidth: 2,
        borderRadius: 3,
        borderStyle: 'solid',
        boxShadow: '0 0 0 2px white',
      },
      '::after': {
        position: 'absolute',
        left: 2,
        top: 1,
        bottom: -1,
        ml: '-1px',
        zIndex: 1,
        content: '""',
        display: last ? 'none' : ['none', null, null, 'block'],
        bg: completed ? 'secondary' : 'border',
        width: '2px',
      },
    }}
  >
    {children}
  </Box>
);

const PType = ({ parcelType }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <div
      sx={{
        display: ['none', null, null, 'block'],
        fontWeight: 'medium',
      }}
    >
      {translate(`buyParcel.type.${parcelType}`)}
    </div>
  );
};

const Country = ({ country }) => {
  const language = useSelector(state => getLanguage(state));
  const localizedName = getLocalizedCountryName(country, language);
  return (
    <Box sx={{ display: ['none', null, null, 'flex'], alignItems: 'center' }}>
      <img
        src={'/flags/' + country.toLowerCase() + '.svg'}
        alt={'lippu ' + localizedName}
        sx={{ display: 'block', height: 20, width: 20, mr: 1 }}
        onError={e => (e.target.style = 'visibility: hidden')}
      />
      {localizedName}
    </Box>
  );
};

const ProductAndPrice = ({ country, product: { localizedName, displayName, price, services }, type }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const language = useSelector(state => getLanguage(state));
  /*
  const productPrefix =
    country === 'FI'
      ? ''
      : country === 'AX'
      ? translate('buyParcel.progress.aland')
      : translate('buyParcel.progress.abroad');
  const name =
    productPrefix +
    ' ' +
    ((localizedName && localizedName[language]) || displayName).replace(
      ' ',
      '\xa0'
    );
    */
  let name = ((localizedName && localizedName[language]) || displayName).replace(' ', '\xa0');

  return (
    <div
      sx={{
        display: ['none', null, null, 'block'],
        fontWeight: 'medium',
      }}
    >
      {name} <span sx={{ color: 'primary' }}>{formatPrice(price)}</span>
    </div>
  );
};

const ExtraServices = ({ services = [] }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const translate2 = (id, fallback) => translate(id, {}, { onMissingTranslation: () => fallback });
  return services.map(({ serviceCode, displayName, price }) => {
    const name = translate2(`buyParcel.services.${serviceCode}.title`, displayName);
    return (
      <div
        key={serviceCode}
        sx={{
          fontWeight: 'medium',
          lineHeight: 'normal',
          display: ['none', null, null, 'block'],
        }}
      >
        {name} <span sx={{ color: 'primary' }}>{formatPrice(price)}</span>
      </div>
    );
  });
};

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const { parcelType, country, page: currentPage, ...item } = useSelector(state => state.parcelFlow);
  const ignoredPages = [];
  const pages = getPageOrder(parcelType, country, item);
  const currentIndex = pages.indexOf(currentPage);

  if (currentIndex === -1) {
    return null;
  }

  return (
    <Flex
      sx={{
        flexDirection: ['row', null, null, 'column'],
        justifyContent: 'space-between',
        width: '100%',
      }}
      aria-hidden
    >
      {pages.map((page, index) => {
        const stepCompleted = index < currentIndex;
        const currentStep = index === currentIndex;
        const isLastStep = index === pages.length - 1;
        if (ignoredPages.includes(page)) return null;
        return (
          <Step key={page} completed={stepCompleted} current={currentStep} last={isLastStep}>
            <div sx={{ display: ['none', null, null, 'block'] }}>{translate(`buyParcel.progress.${page}`)}</div>
            {page === PAGE.PARCEL_TYPE && stepCompleted && <PType parcelType={parcelType} />}
            {page === PAGE.SELECT_COUNTRY && stepCompleted && <Country country={country} />}
            {page === PAGE.SELECT_PRODUCT && stepCompleted && (
              <ProductAndPrice country={country} product={item.product} type={parcelType} />
            )}
            {page === PAGE.SELECT_EXTRAS && stepCompleted && <ExtraServices services={item.services} />}
          </Step>
        );
      })}
    </Flex>
  );
};
