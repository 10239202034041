/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Textarea from 'react-textarea-autosize';
import { withFormik, Form } from 'formik';
import * as yup from 'yup';
import Button from '../components/Button';
import FormField from '../components/FormField';
import FullHeightColumn from '../components/FullHeightColumn';
import Link from '../components/Link';
import { goBack, goNext, saveContents } from '../state/parcelFlow';

export const ContentsForm = ({ handleSubmit, isSubmitting }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <div sx={{ maxWidth: 640 }}>
        <p>{translate('buyParcel.contents.description')}</p>
        {/*
        <Link to="">{translate('buyParcel.contents.seeMore')} &rarr;</Link> {/* todo: link */}
        <div sx={{ mb: 3 }} />
        <FormField
          sx={{ width: '100%', minHeight: '60px' }}
          name="contents"
          as={Textarea}
          label={translate('buyParcel.contents.label') + ' *'}
        />
      </div>

      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 3,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const contentsSchema = translate =>
  yup.object().shape({
    contents: yup.string().required(translate('form.requiredField')),
  });

const ContentsFormik = withFormik({
  mapPropsToValues: ({ contents }) => ({
    contents,
  }),
  validationSchema: ({ translate }) => contentsSchema(translate),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },

  displayName: 'ContentsForm',
})(ContentsForm);

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const contents = useSelector(state => state.parcelFlow.contents) || '';

  const onSubmit = useCallback(
    async values => {
      await dispatch(saveContents(values.contents));
      await dispatch(goNext());
    },
    [dispatch]
  );
  const formProps = {
    contents,
    onSubmit,
    translate,
  };

  return (
    <FullHeightColumn>
      <Box sx={{ flex: 'none' }}>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ flex: 'none', color: 'secondary', mt: 0 }}>{translate('buyParcel.contents.title')}</Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <ContentsFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};
