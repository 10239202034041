/** @jsx jsx */
import React from 'react';
import { Field, Form, useFormikContext, withFormik } from 'formik';
import { useCallback, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import { Box, Flex, jsx, Label, Styled } from 'theme-ui';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import Button from '../components/Button';
import CheckboxField from '../components/CheckboxField';
import CheckboxFieldGroup from '../components/CheckboxFieldGroup';
import DropzoneField from '../components/DropzoneField';
import FormField from '../components/FormField';
import FullHeightColumn from '../components/FullHeightColumn';
import Link from '../components/Link';
import RadioField from '../components/RadioField';
import RadioFieldGroup from '../components/RadioFieldGroup';
import { Select } from '../components/Select';
import { SEVERITY, showNotification } from '../state/notifications';
import { goBack, goNext, saveContent } from '../state/parcelFlow';
import { sendAttachmentsToS3 } from '../utils/api';
import EditItemModal from './ContentItemModal';

export const ContentsForm = ({
  values,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  editItem,
}) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const { errors, touched } = useFormikContext();
  const contentTypes = ['document', 'sample', 'gift', 'commercial'];
  const currencys = ['EUR', 'RUB'];
  const addNewItem = useCallback(
    item => {
      setFieldValue('items', [...(values.items || []), item]);
    },
    [values, setFieldValue]
  );

  const items = values.items || [];
  const total = {
    count: 0,
    weight: 0,
    value: 0,
  };
  items.forEach(it => {
    total.count += +it.count || 0;
    total.weight += +it.weight || 0;
    total.value += +it.value || 0;
  });

  let currency = values.currency || 'EUR';
  if (currency === 'EUR') {
    currency = '€';
  }

  return (
    <Form
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Box sx={{ maxWidth: 640 }}>
        <p>
          {translate('buyParcel.customs.intro')}{' '}
          <a href="https://tulli.fi/etusivu" target="_blank" rel="noreferrer">
            {translate('buyParcel.customs.customsLink')}
          </a>
        </p>
        <RadioFieldGroup
          id="senderType"
          error={touched.senderType && errors.senderType}
          label={translate('buyParcel.customs.senderType') + ' *'}
        >
          <Field
            component={RadioField}
            name="senderType"
            id="person"
            label={translate('buyParcel.customs.person')}
          />
          {values.senderType === 'person' && (
            <FormField sx={{ width: '200px', ml: 4 }} name="senderId" />
          )}
          <Field
            component={RadioField}
            name="senderType"
            id="company"
            label={translate('buyParcel.customs.company')}
          />
          {values.senderType === 'company' && (
            <FormField sx={{ width: '200px', ml: 4 }} name="senderId" />
          )}
        </RadioFieldGroup>
        <RadioFieldGroup
          id="contentType"
          error={touched.contentType && errors.contentType}
          label={translate('buyParcel.customs.contentType') + ' *'}
        >
          {contentTypes.map(ct => (
            <Field
              key={ct}
              component={RadioField}
              name="contentType"
              id={ct}
              label={translate('buyParcel.customs.' + ct)}
            />
          ))}
        </RadioFieldGroup>
        <Label>{translate('buyParcel.customs.description1') + ' *'}</Label>
        <p sx={{ mt: 2 }}>{translate('buyParcel.customs.description2')}</p>

        <Label>{translate('buyParcel.customs.currency') + ' *'}</Label>
        <Flex sx={{ alignItems: 'baseline' }}>
          <Box sx={{ width: '120px', mr: 4 }}>
            <Field as={Select} name="currency">
              {currencys.map(c => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Field>
          </Box>
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              editItem({ save: addNewItem, currency: values.currency });
            }}
          >
            {translate('buyParcel.customs.addItem')}
          </Button>
          <Box sx={{ flexGrow: 1 }} />
        </Flex>
        {errors.items && touched.items && (
          <div style={{ color: 'red' }}>{errors.items}</div>
        )}

        {items.length > 0 && (
          <table sx={{ textAlign: 'right', mt: 3 }}>
            <thead>
              <tr>
                <th sx={{ textAlign: 'left' }}>
                  {translate('buyParcel.customs.table.description')}
                </th>
                <th>{translate('buyParcel.customs.table.count')}</th>
                <th sx={{ pl: 2, whiteSpace: 'nowrap' }}>
                  {translate('buyParcel.customs.table.weight')}
                </th>
                <th sx={{ pl: 2, whiteSpace: 'nowrap' }}>
                  {translate('buyParcel.customs.table.value', { currency })}
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((it, index) => (
                <tr key={index}>
                  <td sx={{ textAlign: 'left' }}>{it.description}</td>
                  <td>{it.count}</td>
                  <td>{it.weight}</td>
                  <td>{it.value}</td>
                  <td sx={{ pl: 2 }}>
                    <Button
                      sx={{ py: 1 }}
                      type="button"
                      variant="plain"
                      onClick={() => {
                        editItem({
                          ...it,
                          save: value => {
                            items.splice(index, 1, value);
                            setFieldValue('items', items);
                          },
                          currency: values.currency,
                        });
                      }}
                    >
                      {translate('buyParcel.customs.table.edit')}
                    </Button>
                    <Button
                      sx={{ ml: 2, py: 1 }}
                      type="button"
                      variant="plain"
                      onClick={() => {
                        items.splice(index, 1);
                        setFieldValue('items', items);
                      }}
                    >
                      {translate('buyParcel.customs.table.remove')}
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="4">
                  <hr />
                </td>
              </tr>
              <tr>
                <td sx={{ textAlign: 'left' }}>
                  {translate('buyParcel.customs.table.total')}
                </td>
                <td>{total.count}</td>
                <td>{total.weight}</td>
                <td>{total.value}</td>
              </tr>
            </tbody>
          </table>
        )}

        <Box sx={{ mt: 3 }}>
          <FormField
            sx={{ width: '100%', minHeight: '60px' }}
            name="remarks"
            as={Textarea}
            label={translate('buyParcel.customs.remarks')}
          />
        </Box>

        {values.senderType === 'company' && (
          <Box sx={{ mt: 3 }}>
            <DropzoneField
              name="attachment"
              newLabel={translate('buyParcel.customs.attachments') + ' *'}
              placeholder={translate('buyParcel.customs.dropzoneText')}
              clearButtonText={translate('buyParcel.customs.clear')}
              zoneActiveText={translate('buyParcel.customs.dropzoneActiveText')}
            />
          </Box>
        )}

        <CheckboxFieldGroup
          id="affirmation"
          error={touched.affirmation && errors.affirmation}
        >
          <Field
            component={CheckboxField}
            name="affirmation"
            multiple={false}
            label={
              <>
                {translate('buyParcel.customs.affirmation')}{' '}
                <Link to="/">
                  {translate('buyParcel.customs.conditionsLink')}
                </Link>
              </>
            }
          />
        </CheckboxFieldGroup>
      </Box>

      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 3,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const contentsSchema = (translate, weightClass) =>
  yup.object().shape({
    senderType: yup
      .string()
      .oneOf(['person', 'company'], translate('form.requiredField'))
      .required(translate('form.requiredField')),
    senderId: yup.string().required(translate('form.requiredField')),
    contentType: yup
      .string()
      .oneOf(
        ['document', 'sample', 'gift', 'commercial'],
        translate('form.requiredField')
      )
      .required(translate('form.requiredField')),
    currency: yup
      .string()
      .oneOf(['EUR', 'RUB'], translate('form.requiredField'))
      .required(translate('form.requiredField')),
    items: yup
      .array()
      .min(1, translate('form.requiredField'))
      .required(translate('form.requiredField'))
      .test(
        'Weight is below weigtClass',
        translate('form.invalidTotalWeight', { weightClass }),
        (value, testContext) => {
          const gramToKiloConversion = 1000;
          return (
            value.reduce(
              (previousValue, currentItem) =>
                previousValue + currentItem.weight,
              0
            ) <=
            weightClass * gramToKiloConversion
          );
        }
      ),
    remarks: yup.string(),
    affirmation: yup
      .bool()
      .required(translate('form.requiredField'))
      .oneOf([true], translate('form.requiredField')),
    attachment: yup
      .array()
      .of(yup.mixed())
      .max(4, translate('feedback.message.tooManyFiles', { files: 4 }))
      .ensure()
      .when('senderType', {
        is: 'company',
        then: yup
          .array()
          .of(yup.mixed())
          .ensure()
          .required(translate('form.requiredField')),
      }),
    customerId: yup.string(),
  });

const ContentsFormik = withFormik({
  mapPropsToValues: ({ contents }) => {
    return {
      senderType: '',
      senderId: '',
      contentType: '',
      currency: 'EUR',
      remarks: '',
      affirmation: false,
      attachment: contents.attachment || [],
      customerId: '',
      ...contents,
      items: [...(contents.items || [])],
    };
  },
  validationSchema: ({ translate, weightClass }) =>
    contentsSchema(translate, weightClass),
  handleSubmit: async (values, { props: { onSubmit }, ...actions }) => {
    if (!values.customerId) values.customerId = uuid();
    onSubmit({ ...values }, actions);
  },

  displayName: 'ContentsForm',
})(ContentsForm);

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const contents = useSelector(state => state.parcelFlow.content) || '';
  const { weightClass } = useSelector(state => state.parcelFlow.product);

  const [item, setItem] = useState();
  const editItem = useCallback(item => {
    setItem(item);
  }, []);
  const closeModal = useCallback(() => setItem(null), []);

  const onSubmit = useCallback(
    async values => {
      try {
        if (values.attachment.length > 0) {
          dispatch(
            showNotification('buyParcel.customs.uploading', SEVERITY.INFO)
          );
          await sendAttachmentsToS3({
            attachment: values.attachment,
            customerId: values.customerId,
          });
        }
        values.attachment = [];
        dispatch(saveContent(values));
        dispatch(goNext());
      } catch (error) {
        dispatch(
          showNotification('buyParcel.customs.uploadingFailed', SEVERITY.ERROR)
        );
        console.error(error);
      }
    },
    [dispatch]
  );
  const formProps = {
    contents,
    editItem,
    onSubmit,
    translate,
    weightClass,
  };

  return (
    <FullHeightColumn>
      <EditItemModal item={item} close={closeModal} />

      <Box sx={{ flex: 'none' }}>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>
      <Styled.h1 sx={{ flex: 'none', color: 'secondary', mt: 0 }}>
        {translate('buyParcel.customs.title')}
      </Styled.h1>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <ContentsFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};
