/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { goBack, goNext, saveRecipient } from '../state/parcelFlow';
import { postalCodeRegExpForCountry } from '../utils/regExp';
import { Button, PostalCodeAndCityFields } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';
import { Form } from 'formik';
import AutocompleteStreetField from '../components/AutocompleteStreetField';

const RecipientForm = ({ buttonText, handleSubmit, isSubmitting }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Form
      sx={{
        flex: 1,
        width: '100%',
        maxWidth: 640,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Flex sx={{ flexWrap: 'wrap', mx: -2 }}>
          <div sx={{ flexGrow: 1, width: ['100%', 'auto'], px: 2, zIndex: 3 }}>
            <AutocompleteStreetField label={translate('buyParcel.express.recipient.street')} country="FI" />
          </div>
        </Flex>
        <PostalCodeAndCityFields postcodeLabel={translate('buyParcel.express.recipient.postcode')} />
      </Box>
      <Flex
        sx={{
          flex: 'none',
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 3,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {buttonText || translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const addressSchema = translate =>
  yup.object().shape({
    street: yup.string().required(translate('form.requiredField')),
    apartment: yup.string(),
    postcode: yup
      .string()
      .matches(postalCodeRegExpForCountry('FI'), translate('form.invalidPostcode'))
      .required(translate('form.requiredField')),
    city: yup.string(),
  });

const RecipientFormik = withFormik({
  mapPropsToValues: ({ recipient }) =>
    Object.assign({
      name: recipient.name || '',
      phone: recipient.phone || '',
      email: recipient.email || '',
      street: recipient.street || '',
      apartment: recipient.apartment || '',
      postcode: recipient.postcode || '',
      city: recipient.city || '',
    }),

  validationSchema: ({ translate }) => {
    return addressSchema(translate);
  },

  handleSubmit: (values, { props: { onSubmit } }) => {
    return onSubmit(values);
  },

  displayName: 'RecipientInputForm',
})(RecipientForm);

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const recipient = useSelector(state => state.parcelFlow.recipient) || {};

  const onSubmit = useCallback(
    async values => {
      await dispatch(saveRecipient(values));
      await dispatch(goNext());
    },
    [dispatch]
  );
  const formProps = {
    recipient,
    onSubmit,
    translate,
  };

  return (
    <FullHeightColumn>
      <Box sx={{ flex: 'none' }}>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ flex: 'none', color: 'secondary', mt: 0 }}>
        {translate('buyParcel.express.recipient.title')}
      </Styled.h1>

      <Box sx={{ my: 2, bg: 'blueLighter', borderRadius: 2, p: 3 }}>
        {translate('buyParcel.express.recipient.note')}
      </Box>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <RecipientFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};
